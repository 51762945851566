body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  min-height: 100vh;
  background-color: #212121 !important;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}


/* HOME */

.home .payment_check {
  background-color: #212121;
  height: 100vh;
  width: 100%;
}
.home .login-box {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 400px;
  padding: 40px;
  transform: translate(-50%, -50%);
  background: rgba(24, 20, 20, 0.987);
  box-sizing: border-box;
  box-shadow: 0 15px 25px rgba(0,0,0,.6);
  border-radius: 10px;
}

.home .login-box .user-box {
  position: relative;
}

.home .login-box .user-box input {
  width: 100%;
  padding: 10px 0;
  font-size: 16px;
  color: #fff;
  margin-bottom: 30px;
  border: none;
  border-bottom: 1px solid #fff;
  outline: none;
  background: transparent;
}

.home .login-box .user-box label {
  position: absolute;
  top: 0;
  left: 0;
  padding: 10px 0;
  font-size: 16px;
  color: #fff;
  pointer-events: none;
  transition: .5s;
}

.home .login-box .user-box input:focus ~ label,
.home .login-box .user-box input:valid ~ label {
  top: -20px;
  left: 0;
  color: #bdb8b8;
  font-size: 12px;
}

.home .login-box .form a {
  position: relative;
  display: inline-block;
  padding: 10px 20px;
  color: #ffffff;
  font-size: 16px;
  text-decoration: none;
  text-transform: uppercase;
  overflow: hidden;
  transition: .5s;
  margin-top: 40px;
  letter-spacing: 4px;
  cursor: pointer;
}

.home .login-box a:hover {
  background: #03f40f;
  color: #fff;
  border-radius: 5px;
  box-shadow: 0 0 5px #03f40f, 0 0 25px #03f40f, 0 0 50px #03f40f, 0 0 100px #03f40f;
}

.home .login-box a span {
  position: absolute;
  display: block;
}

@keyframes btn-anim1 {
  0% {
      left: -100%;
  }

  50%,100% {
      left: 100%;
  }
}

.home .login-box a span:nth-child(1) {
  bottom: 2px;
  left: -100%;
  width: 100%;
  height: 2px;
  background: linear-gradient(90deg, transparent, #03f40f);
  animation: btn-anim1 2s linear infinite;
}
/* HOME END */





/* Loading */
.loadingWrapper .loading {
  background-color: rgba(0, 0, 0, 0);
}

.loadingWrapper .loading-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(130, 21, 21, 0.4);
  }

@keyframes spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
  
.loadingWrapper svg {
  animation: spin 2s linear infinite;
}

.d-none {
  display: none !important;
}

/* Loading END */



/* CREDIT CARD */
.personalPayment .App-payment {
  padding: 30px;
}

.personalPayment form {
  margin: 30px auto 0;
  max-width: 400px;
}

.personalPayment .form-actions {
  margin-top: 15px;
}

.personalPayment .App-cards {
  margin: 0 auto;
  max-width: 1280px;
  text-align: center;
}

.personalPayment .App-cards-list {
  display: flex;
  margin-top: 30px;
  flex-wrap: wrap;
  justify-content: space-between;
}

.personalPayment .App-cards-list > * {
  transform: scale(0.8);
  margin-bottom: 30px !important;
}

.personalPayment .App-highlight {
  font-family: serif;
  margin: 15px auto 0;
  max-width: 300px;
}

.personalPayment .App-highlight > div {
  padding-left: 40px;
}

.personalPayment .App-badges {
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.personalPayment .App-badges a {
  display: block;
}

.personalPayment .App-badges a + a {
  margin-top: 18px;
}

.personalPayment .github__btn {
  align-items: center;
  background-color: #ccc;
  border-radius: 4px;
  color: #444;
  display: flex;
  line-height: 1;
  padding: 8px 12px;
}

.personalPayment .github__btn:hover {
  color: #444;
  text-decoration: none;
}

.personalPayment .github__btn img {
  display: inline-block;
  height: 28px;
  margin-right: 8px;
}

.personalPayment .App-credits {
  background-color: #000;
  color: #fff;
  line-height: 50px;
  text-align: center;
}

.personalPayment .App-credits a {
  color: #fff;
  font-weight: bold;
}

@media screen and (min-width: 600px) {
  .App-badges {
    flex-direction: row;
  }

  .App-badges a + a {
    margin-top: 0;
    margin-left: 18px;
  }
}

/* CREDIT CARD END */

